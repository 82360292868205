import {
  LOAD,
  UPDATE,
  CREATE,
  UPDATE_PAL_CONFIG,
  CREATE_PAL_CONFIG,
  CREATE_PAL_CORE_DIRECT,
} from 'model/fspConfigPayALoan';
import {
  load,
  update,
  create,
  update as updatePalConfig,
  create as createPalConfig,
} from 'sdk/fspConfigPayALoan';
import { takeLatest } from 'redux-saga/effects';
import { callAction, ORM } from '../util';
import { loadFSPProfileAction } from './export';

export const createFspPalConfig = () =>
  callAction({
    api: create,
    dataName: ORM,
    formatSuccessMessage: () => `FSP Config has been successfully created`,
    type: CREATE,
  });

export const createPalCoreDirect = () =>
  callAction({
    api: create,
    dataName: ORM,
    formatSuccessMessage: () => `FSP Config has been successfully created`,
    type: CREATE_PAL_CORE_DIRECT,
  });

const updateFspPalConfig = () =>
  callAction({
    api: update,
    dataName: ORM,
    formatSuccessMessage: () => `Pay A Loan Config successfully updated`,
    type: UPDATE,
  });

function* updateAndPopulateExport(action) {
  const updateAction = updateFspPalConfig();

  if (yield updateAction(action)) {
    yield loadFSPProfileAction(action);
  }
}

export default function* saga() {
  yield takeLatest(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeLatest(
    UPDATE_PAL_CONFIG.ACTION,
    callAction({
      api: updatePalConfig,
      dataName: ORM,
      type: UPDATE_PAL_CONFIG,
      formatSuccessMessage: () => `Pay A Loan Config successfully updated`,
    })
  );

  yield takeLatest(
    CREATE_PAL_CONFIG.ACTION,
    callAction({
      api: createPalConfig,
      dataName: ORM,
      type: CREATE_PAL_CONFIG,
      formatSuccessMessage: () => `Pay A Loan Config successfully created`,
    })
  );

  yield takeLatest(CREATE_PAL_CORE_DIRECT.ACTION, createPalCoreDirect);
  yield takeLatest(CREATE.ACTION, createFspPalConfig);
  yield takeLatest(UPDATE.ACTION, updateAndPopulateExport);
}
