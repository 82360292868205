import { client, formatQueryParams } from 'util/sdk';
import { ON } from 'consts';
import { GET } from 'util/api';

const returnModel = { name: 'schedtran' };
const schedTransURL = (fspId) => `/fsps/${fspId}/schedtrans`;

const exactValue = (field) => (value) => ({ [field]: value });
const rangeValues = (field) => (props) => {
  const [valueFrom, valueTo] = props;
  return {
    [`startwith(${field})`]: valueFrom,
    [`endwith(${field})`]: valueTo,
  };
};
const orderByValue = (field) => (value) => ({ order: `${field}:${value}` });
const manuallyDeletedValue = (deletedOnlyField) => (value) => ({
  [deletedOnlyField]: value,
});

const formatQueryOperations = {
  alltrans: exactValue('alltrans'),
  modelId: exactValue('modelid'),
  offset: exactValue('offset'),
  limit: exactValue('limit'),
  reviewed: exactValue('reviewed'),
  canceled: exactValue('canceled'),
  destination: exactValue('destination'),
  deletedOnly: exactValue('deletedOnly'),
  manualDeletedOnly: manuallyDeletedValue('manualDeletedOnly'),
  destinationLike: exactValue([`like(destination)`]),
  resultReason: exactValue('resultreason'),
  reviewedBy: exactValue('reviewedby'),
  reviewedOnRange: rangeValues('reviewedon'),
  amountRange: rangeValues('amount'),
  deliver: exactValue('deliver'),
  fspPayerId: exactValue('fsppayerid'),
  createdAtRange: rangeValues('createdat'),
  sendOnRange: rangeValues('sendon'),
  transactionType: exactValue('transactiontype'),
  canceledBy: exactValue('canceledby'),
  canceledOnRange: rangeValues('canceledon'),
  id: exactValue('id'),
  processedOnlyOffOn: exactValue('processedonlyoffon'),
  orderByDeliver: orderByValue('deliver'),
  orderByDeliverOn: orderByValue('deliveron'),
  orderByDestination: orderByValue('destination'),
  orderByTransactionType: orderByValue('transactionType'),
  orderByFraudScore: orderByValue('fraudscore'),
  orderByAmount: orderByValue('amount'),
  orderByCreatedAt: orderByValue('createdAt'),
  loanNumber: exactValue('loannumber'),
  loanNameLike: exactValue('loanname'),
  autopayOffOn: exactValue('autopayoffon'),
  a2atracenumber: exactValue('a2atracenumber'),
  orderBySendOn: orderByValue('sendon'),
  payerId: exactValue('payerid'),
  amount: exactValue('amount'),
  confirmationNumber: exactValue('confirmnbr'),
  status: exactValue('status'),
  destinationMask: exactValue('destinationmask'),
  fundingMask: exactValue('fundingmask'),
  confirmNumber: exactValue('confirmnbr'),
};

const getQueryParams = (params) => {
  return params.reduce((prev, curr) => ({ ...prev, ...curr }), {});
};

export const load = async ({ fspId, payerId, ...params }) => {
  const formatQueryArray = formatQueryParams({
    ...params,
    // Strict Rule, resultReason parameter must follow these rules in order to work:
    // 1. Just one item, do not send quotes, example: resultReason: cancel_ontrac
    // 2. More than one item, send json array format, example: resultReason: ["cancel_ontrac","cancel_batch"]
    resultReason:
      typeof params.resultReason === 'string'
        ? params.resultReason
        : JSON.stringify(params.resultReason),
  });

  return client.executeRequest(GET, schedTransURL(fspId), {
    queryParams: {
      payerid: payerId,
      expand: ['dda'],
      suspendedP2p: ON,
      // Spread all parameters needed by the API here
      ...getQueryParams(formatQueryArray(formatQueryOperations)),
    },
    returnModel,
  });
};

export const decryptDestination = async (transaction) =>
  client
    .fsps(transaction.fspId)
    .scheduledTransactions(transaction.id)
    .decryptDestinationAccountNumber(transaction);

export const decryptFunding = async (transaction) =>
  client
    .fsps(transaction.fspId)
    .scheduledTransactions(transaction.id)
    .decryptFundingAccountNumber(transaction);
