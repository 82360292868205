import { PAYER_PREFIX } from 'consts';
import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createImportConst,
  createUpdateConst,
} from '../util';
import addDerivedLimitRule from './util/limitRule';
import { addDerived as addDerivedPayerType } from './payerType';
import { addDerived } from './util/fspConfig';
import { derived as derivedOTPEventConfig } from './util/oneTimePasscodeEventConfig';
import { addDerived as addDerivedUserRole } from './util/userRole';
import { addDerived as addDerivedFspPalConfig } from './util/fspConfigPayALoan';
import { addDerived as addDerivedFees } from './util/fspProcessingFees';

export default class Export extends Model {
  static get modelName() {
    return 'Export';
  }

  static reducer(
    { type, data },
    Export,
    {
      FSPConfig,
      FspCoreConfig,
      FspConfigPayALoan,
      Limit,
      LimitRule,
      OneTimePasscodeEventConfig,
      PayerType,
      FSPPaynet,
      Rtn,
      SSOUri,
      UserRole,
      FSPProcessingFees,
    }
  ) {
    const { fspId, id } = data?.props ?? {};

    switch (type) {
      case ADD.SUCCESS:
        Export.upsert({ id: fspId, ...(data?.response ?? {}) });
        break;

      case UPLOAD_FSP_PROFILE.ACTION:
      case LOAD_FSP_PROFILE.SUCCESS: {
        const {
          fspconfig,
          fspcoreconfig = {},
          fsppalconfig = {},
          fspotpevent = {},
          fsppayertype = [],
          fsppaynets = [],
          fsprtn = [],
          fspprocessingfees = [],
          limit = [],
          limitrule = [],
          ssouri = [],
          userrole = [],
        } = data?.response ?? {};

        const derivedOtpEvent = derivedOTPEventConfig(fspotpevent);
        const derivedFspConfig = addDerived(fspconfig);

        const exportData = {
          ...data?.response,
          id: fspId,
          fspconfig: derivedFspConfig,
          fspotpevent: derivedOtpEvent,
          fsppalconfig,
        };

        Export.upsert(exportData);

        FSPConfig.upsert(derivedFspConfig);
        FspCoreConfig.upsert(fspcoreconfig);
        FspConfigPayALoan.upsert(fsppalconfig);
        OneTimePasscodeEventConfig.upsert(derivedOtpEvent);

        PayerType.delete();
        fsppayertype.forEach((r) => PayerType.create(addDerivedPayerType(r)));

        FSPPaynet.delete();
        fsppaynets.forEach((r) => FSPPaynet.upsert(r));

        Rtn.delete();
        fsprtn.forEach((r) => Rtn.upsert(r));

        Limit.delete();
        limit.forEach((r) => {
          // exclude custom payer level rules because custom rules don't apply when importing
          // into a different environment
          if (
            type !== UPLOAD_FSP_PROFILE.ACTION ||
            !r.type.startsWith(PAYER_PREFIX)
          )
            Limit.upsert(r);
        });

        LimitRule.delete();
        limitrule.forEach((r) => {
          if (Limit.idExists(r.limitId)) {
            r.limitTypeDerived = Limit.withId(r.limitId).type;
          }
          LimitRule.upsert(addDerivedLimitRule(r));
        });

        SSOUri.delete();
        ssouri.forEach((r) => SSOUri.upsert(r));

        FSPProcessingFees.delete();
        fspprocessingfees.forEach((r) =>
          FSPProcessingFees.upsert(addDerivedFees(r))
        );

        UserRole.delete(); // If we don't clean up, duplicate records would show
        userrole.forEach((r) => UserRole.create(addDerivedUserRole(r)));

        break;
      }

      case REMOVE.SUCCESS:
        if (Export.idExists(fspId)) {
          Export.withId(id).delete();
        }
        break;

      case UPDATE_FSP_PROFILE.SUCCESS: {
        const { fspId: id, src = {} } = data;

        Export.upsert({ id, ...src });
        break;
      }

      case UPDATE.SUCCESS: {
        const { response, type } = data;
        const id = response.id;

        const model = Export.withId(response.fspId);
        const ref = model.ref;
        const section = ref[type];
        const idRemoved = section.filter((r) => r.id !== id);

        // in-place update
        ref[type] = [...idRemoved, response];

        model.update(ref);
        break;
      }

      default:
        break;
    }
  }

  static get fields() {
    return {
      billertopfsp: attr(),
      coreprocessor: attr(),
      fspconfig: attr(),
      fspcoreconfig: attr(),
      fsppalconfig: attr(),
      fspinsightevent: attr(),
      fspotpevent: attr(),
      fsppayertype: attr(),
      fsppaynets: attr(),
      fspprocessingfees: attr(),
      fsprtn: attr(),
      limit: attr(),
      limitrule: attr(),
      ssouri: attr(),
      userrole: attr(),
    };
  }
}

const name = Export.modelName;

export const ADD = createAddConst(name);
export const IMPORT_FSP_PROFILE = createImportConst(name);
export const LOAD_FSP_PROFILE = createLoadConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE_FSP_PROFILE = createUpdateConst(name);
export const UPDATE = createUpdateConst(`${name}_rtn`);
export const UPLOAD_FSP_PROFILE = createLoadConst(`${name}_upload`);

export const add = createAction(ADD);
export const loadFSPProfile = createAction(LOAD_FSP_PROFILE);
export const importFSPProfile = createAction(IMPORT_FSP_PROFILE);
export const updateFSPProfile = createAction(UPDATE_FSP_PROFILE);
export const uploadFSPProfile = createAction(UPLOAD_FSP_PROFILE);
