import { Redirect, Route, Switch } from 'react-router-dom';
import { withViewService } from 'components/common/permissions';
import { useSelector } from 'react-redux';
import DefaultDetail from './DefaultDetail';
import PayerDetail from './PayerDetail';
import Payers from './Payers';
import { BASE_PATH, DETAIL_PATH, SEARCH_PATH } from './paths';
import PalGuestLink from './PalGuestLink';
import { useParamId } from '../../../hooks';
import { transactOnBehalfPalOnSelector } from '../../../selectors/userFspConfig';
import { PAY_A_LOAN_OFF_ON } from '../../../consts';
import { isOn } from '../../../util';

const Service = (disable) => {
  const { fspId, payerId } = useParamId();
  const showGuestPaymentOnOff = useSelector((state) =>
    transactOnBehalfPalOnSelector(state, fspId, payerId)
  );

  return (
    <Switch>
      <Route component={Payers} path={SEARCH_PATH} />
      <Route component={PayerDetail} path={`${DETAIL_PATH}/:fspId/:payerId`} />
      <Route component={DefaultDetail} path={DETAIL_PATH} />
      {/* <Route component={PalGuestLink} path={PAL_GUEST_PATH} /> */}
      <Redirect from={BASE_PATH} to={SEARCH_PATH} />
    </Switch>
  );
};

export default withViewService(Service);
