import { Model, attr } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
} from '../util';
import { addDerived } from './util/fspProcessingFees';

export default class FSPProcessingFees extends Model {
  static get modelName() {
    return 'FSPProcessingFees';
  }

  static reducer({ type, data }, FSPProcessingFees) {
    switch (type) {
      case ADD.SUCCESS:
        FSPProcessingFees.create(addDerived(data?.response));
        break;

      case CREATE.SUCCESS:
        FSPProcessingFees.create(addDerived(data?.response));
        break;

      case LOAD.SUCCESS:
        FSPProcessingFees.delete();
        data?.response?.forEach((r) => FSPProcessingFees.upsert(addDerived(r)));
        break;

      case LOAD.FAILURE:
        FSPProcessingFees.delete();
        break;

      case REMOVE.SUCCESS:
        if (FSPProcessingFees.idExists(data?.response?.id)) {
          FSPProcessingFees.withId(data?.response?.id).delete();
        }
        break;

      case UPDATE.SUCCESS:
        FSPProcessingFees.upsert(addDerived(data?.response));
        break;
      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      offset: attr(),
      id: attr(),
      href: attr(),
      feesAmount: attr(),
      feesAmountDerived: attr(),
      description: attr(),
      transactionTypeIdentifier: attr(),
      fundingTypeId: attr(),
      fundingType: attr(),
      fundingTypeDerived: attr(),
      disburseTransactionTypeIdentifier: attr(),
      disburseTransactionTypeIdentifierDerived: attr(),
      insertedAt: attr(),
      insertedUser: attr(),
      insertedAdminUserId: attr(),
      updatedAt: attr(),
      updatedUser: attr(),
      updatedAdminUserId: attr(),
    };
  }

  static options = {
    idAttribute: 'id',
  };
}

const name = FSPProcessingFees.modelName;

export const CREATE = createRequestConst(name);
export const create = createAction(CREATE);

export const LOAD = createRequestConst(name);
export const load = createAction(LOAD);

export const UPDATE = createUpdateConst(name);
export const update = createAction(UPDATE);

export const REMOVE = createDeleteConst(name);
export const remove = createAction(REMOVE);

export const ADD = createAddConst(name);
export const add = createAction(ADD);
