import {
  CREATE,
  LOAD,
  LOAD_ID,
  UPDATE,
  UPDATE_EXTENDED,
  UPDATE_PREVIEW_SSO,
  PREVIEW_SSO,
  UPDATE_PREVIEW_RECIPIENT,
  PREVIEW_RECIPIENT,
} from 'model/fspConfig';
import { call, takeEvery, takeLatest, put } from 'redux-saga/effects';
import { create, load, loadById, update } from 'sdk/fspConfig';
import { registerPayer } from 'sdk/payer';
import { LOAD_PAYER } from 'model/viewAs';
import { isOff, isOn } from 'util/index';
import { loadFSPProfile } from 'model/export';
import {
  CREATE as CREATE_FSP_AI_EVENT,
  REMOVE as REMOVE_FSP_AI_EVENT,
  UPDATE as UPDATE_FSP_AI_EVENT,
} from 'model/fspAiEvents';
import {
  create as createFspAiEvent,
  update as updateFspAiEvent,
  remove as removeFspAiEvent,
} from 'sdk/fspAiEvents';
import {
  callAction,
  createActionFailure,
  createActionSuccess,
  ORM,
} from '../util';
import {
  formatSuccessMessage,
  FSP_CONFIG_LABEL,
  FSP_NAME_CODE,
  SSO_OVERWRITE_PREVIEW,
  UPDATED,
  REMOVED,
  ADDED,
  MISS_A_PAYMENT_EVENT_ID,
  MISS_A_PAYMENT_OFF_ON,
  MISS_A_PAYMENT_NOTIFICATION_OFF_ON,
  REDUCE_MY_BILL_EVENT_ID,
  REDUCE_MY_BILL_NOTIFICATION_OFF_ON,
  REDUCE_MY_BILL_OFF_ON,
  OFF,
} from '../consts';

const keyName = { key: FSP_NAME_CODE, name: FSP_CONFIG_LABEL };

export const createFspConfig = () =>
  callAction({
    api: create,
    dataName: ORM,
    formatSuccessMessage: () => `FSP Config has been successfully created`,
    type: CREATE,
  });

const removeAiEventAction = callAction({
  api: removeFspAiEvent,
  dataName: ORM,
  formatSuccessMessage: formatSuccessMessage(keyName, REMOVED),
  type: REMOVE_FSP_AI_EVENT,
});

const updateAiEventAction = callAction({
  api: updateFspAiEvent,
  dataName: ORM,
  formatSuccessMessage: formatSuccessMessage(keyName, UPDATED),
  type: UPDATE_FSP_AI_EVENT,
});

const createAiEventAction = callAction({
  api: createFspAiEvent,
  dataName: ORM,
  formatSuccessMessage: formatSuccessMessage(keyName, ADDED),
  type: CREATE_FSP_AI_EVENT,
});

// Send API request to update or create FSP AI Events
// Update or Delete FSP AI Event if it exists
// Miss a Payment
function* updateMissAPaymentAiEvent({ values }) {
  const missAPaymentEnabledOffOn = values[MISS_A_PAYMENT_OFF_ON];
  if (values?.missAPaymentId) {
    if (isOff(missAPaymentEnabledOffOn)) {
      const data = { id: values.missAPaymentId, fspId: values.fspId };
      yield removeAiEventAction({ data });
    } else {
      const data = {
        id: values.missAPaymentId,
        fspId: values.fspId,
        notifyOffOn: values[MISS_A_PAYMENT_NOTIFICATION_OFF_ON],
      };
      yield updateAiEventAction({ data });
    }
  } // Create FSP AI Event if it doesn't exist
  else if (!values?.missAPaymentId && isOn(missAPaymentEnabledOffOn)) {
    const data = {
      fspId: values.fspId,
      insightEventIdStr: MISS_A_PAYMENT_EVENT_ID,
      notifyOffOn: values[MISS_A_PAYMENT_NOTIFICATION_OFF_ON],
      pilotOffOn: OFF,
    };
    yield createAiEventAction({ data });
  }
}

// Reduce My Bill
function* updateReduceMyBillAiEvent({ values }) {
  const reduceMyBillEnabledOffOn = values[REDUCE_MY_BILL_OFF_ON];
  if (values?.reduceMyBillId) {
    if (isOff(reduceMyBillEnabledOffOn)) {
      const data = { id: values.reduceMyBillId, fspId: values.fspId };
      yield removeAiEventAction({ data });
    } else {
      const data = {
        id: values.reduceMyBillId,
        fspId: values.fspId,
        notifyOffOn: values[REDUCE_MY_BILL_NOTIFICATION_OFF_ON],
      };
      yield updateAiEventAction({ data });
    }
  } // Create FSP AI Event if it doesn't exist
  else if (!values?.reduceMyBillId && isOn(reduceMyBillEnabledOffOn)) {
    const data = {
      fspId: values.fspId,
      insightEventIdStr: REDUCE_MY_BILL_EVENT_ID,
      notifyOffOn: values[REDUCE_MY_BILL_NOTIFICATION_OFF_ON],
      pilotOffOn: OFF,
    };
    yield createAiEventAction({ data });
  }
}

function* updateAndPopulateExport(action) {
  const updateAction = callAction({
    api: update,
    dataName: ORM,
    formatSuccessMessage: formatSuccessMessage(keyName, UPDATED),
    type: UPDATE,
  });

  if (yield updateAction(action)) {
    yield put(loadFSPProfile({ fspId: action?.data?.fspId }));
  }
}

function* updateFspConfigAndAiEvents(action) {
  const updateAction = callAction({
    api: update,
    dataName: ORM,
    formatSuccessMessage: formatSuccessMessage(keyName, UPDATED),
    type: UPDATE,
  });

  const fspAiEventsValues = {
    ...action?.data?.fspAiEvents,
    fspId: action?.data?.fspId,
  };
  const {
    hasMissAPaymentChanged,
    hasReduceMyBillChanged,
    hasFSPConfigChanged,
  } = fspAiEventsValues;
  // Update FSP Config
  if (hasFSPConfigChanged) {
    yield updateAction(action);
  }
  // Update FSP AI Events - Miss a Payment and Reduce My Bill
  if (hasMissAPaymentChanged) {
    yield call(updateMissAPaymentAiEvent, { values: fspAiEventsValues });
  }
  if (hasReduceMyBillChanged) {
    yield call(updateReduceMyBillAiEvent, { values: fspAiEventsValues });
  }
  // Load FSP Profile to update the UI
  yield put(loadFSPProfile({ fspId: action?.data?.fspId }));
  // This is sent to clear UPDATE_EXTENDED.BUSY state
  yield put(createActionSuccess(UPDATE_EXTENDED)(action.data));
}

const updatePreviewRecipient = callAction({
  api: update,
  dataName: ORM,
  type: UPDATE_PREVIEW_RECIPIENT,
});

const updatePreviewSsoAction = callAction({
  api: update,
  dataName: ORM,
  type: UPDATE_PREVIEW_SSO,
});

const registerAction = callAction({
  api: registerPayer,
  dataName: ORM,
  type: LOAD_PAYER,
});

function* previewSso(action) {
  const { fspConfig, payerId } = action.data;

  const overwritePreview = fspConfig?.ssoConfig?.[SSO_OVERWRITE_PREVIEW];
  if (overwritePreview && typeof overwritePreview === 'object') {
    fspConfig.ssoConfig[SSO_OVERWRITE_PREVIEW] =
      JSON.stringify(overwritePreview);
  }

  const result = yield updatePreviewSsoAction({ data: fspConfig });
  if (result) {
    yield registerAction({ data: { fspId: fspConfig.fspId, payerId } });
    yield put(createActionSuccess(PREVIEW_SSO)());
  } else {
    yield put(createActionFailure(PREVIEW_SSO)());
  }
}

function* previewRecipient(action) {
  const { fspConfig } = action.data;

  const overwritePreview = fspConfig?.ssoConfig?.[SSO_OVERWRITE_PREVIEW];
  if (overwritePreview && typeof overwritePreview === 'object') {
    fspConfig.ssoConfig[SSO_OVERWRITE_PREVIEW] =
      JSON.stringify(overwritePreview);
  }

  const result = yield updatePreviewRecipient({ data: fspConfig });
  if (result) {
    yield put(createActionSuccess(PREVIEW_RECIPIENT)());
  } else {
    yield put(createActionFailure(PREVIEW_RECIPIENT)());
  }
}

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({
      api: loadById,
      dataName: ORM,
      type: LOAD_ID,
    })
  );

  yield takeEvery(CREATE.ACTION, createFspConfig);
  yield takeLatest(UPDATE.ACTION, updateAndPopulateExport);
  yield takeLatest(UPDATE_EXTENDED.ACTION, updateFspConfigAndAiEvents);
  yield takeEvery(PREVIEW_RECIPIENT.ACTION, previewRecipient);
  yield takeEvery(PREVIEW_SSO.ACTION, previewSso);
}
