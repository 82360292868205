const DETAIL = 'detail';
const SEARCH = 'search';
const GUEST = 'guestUrl';

export const BASE_PATH = '/service';
export const DETAIL_PATH = `${BASE_PATH}/${DETAIL}`;
export const SEARCH_PATH = `${BASE_PATH}/${SEARCH}`;
export const PAL_GUEST_PATH = `${BASE_PATH}/${GUEST}/${DETAIL}`;

export const getLocationFromPayer = ({ zfspId, id }) => {
  return {
    pathname: `${DETAIL_PATH}/${zfspId}/${id}`,
    state: { add: true },
  };
};
