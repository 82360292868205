import { LOAD, REMOVE, UPDATE, ADD, CREATE } from 'model/fspProcessingFees';
import { load, remove, create, add, update } from 'sdk/fspProcessingFees';
import { takeLatest } from 'redux-saga/effects';
import { callAction, ORM } from '../util';
import { loadFSPProfileAction } from './export';

export const createFspProcessingFees = () =>
  callAction({
    api: create,
    dataName: ORM,
    type: CREATE,
  });

export const updateFspProcessingFees = () =>
  callAction({
    api: load,
    dataName: ORM,
    type: LOAD,
  });

const addFspProcessingFeesConfig = () =>
  callAction({
    api: add,
    dataName: ORM,
    formatSuccessMessage: () => `Processing Fees Config successfully updated`,
    type: ADD,
  });

function* updateAndPopulateExport(action) {
  const updateAction = updateFspProcessingFees();

  if (yield updateAction(action)) {
    yield loadFSPProfileAction(action);
  }
}

export default function* saga() {
  yield takeLatest(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeLatest(
    REMOVE.ACTION,
    callAction({
      api: remove,
      dataName: ORM,
      type: REMOVE,
      formatSuccessMessage: () => `Fee successfully removed`,
    })
  );

  yield takeLatest(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      type: UPDATE,
      formatSuccessMessage: () => `Fee successfully updated`,
    })
  );

  yield takeLatest(
    ADD.ACTION,
    callAction({
      api: add,
      dataName: ORM,
      type: ADD,
      formatSuccessMessage: () => `Fee successfully added`,
    })
  );

  yield takeLatest(CREATE.ACTION, createFspProcessingFees);
  yield takeLatest(ADD.ACTION, addFspProcessingFeesConfig);
  yield takeLatest(LOAD.ACTION, updateAndPopulateExport);
}
