import { makeStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { TRANSACT_ON_BEHALF_PAL_OFF_ON } from '../../../../consts';
import { isOn } from '../../../../util';
import { withViewService } from '../../../common/permissions';
import { transactOnBehalfPalOnSelector } from '../../../../selectors/userFspConfig';

const useStyles = makeStyles(() => ({
  paper: {
    display: 'grid',
    height: 75,
    margin: 'auto',
    width: 300,
  },
  root: {
    display: 'grid',
    height: '80vh',
  },
  text: {
    margin: 'auto',
  },
}));

const PalGuestLink = (fspId, payerId) => {
  const classes = useStyles();
  const isOnTransactOnBehalfPalSelector = useSelector((state) =>
    transactOnBehalfPalOnSelector(state, fspId, payerId)
  );
  const palTransactOn = isOn(TRANSACT_ON_BEHALF_PAL_OFF_ON);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Button
          size="medium"
          variant="contained"
          fullWidth="false"
          href="https://dev1ssoui.payrailzdev.com/v2/pal"
        >
          PAL Guest Payment
        </Button>
      </Paper>
    </div>
  ); /*: (
    <Typography variant="h6" className={classes.paper}>
      PAL Guest is not enabled
    </Typography>
  ); */
};

export default withViewService(PalGuestLink);
