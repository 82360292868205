import { client } from 'util/sdk';
import { GET, POST, PUT } from 'util/api';
import { FSPS } from 'consts';

const returnModel = { name: 'palConfig' };

export const loadURL = (fspId) => `/v2/${FSPS}/${fspId}/fspconfigs/pal`;
const updateURL = (fspId, id) => `${loadURL(fspId)}/${id}`;

export const load = (fspId) => {
  return client.executeRequest(GET, loadURL(fspId), {
    returnModel,
  });
};

export const update = ({ fspId, id, ...params }, auth) => {
  return client.executeRequest(PUT, updateURL(fspId, id), {
    data: { ...params },
    queryParams: {
      adminUserId: auth.id,
      lastUpdatedDate: params.updatedAt,
    },
    returnModel,
  });
};

export const create = ({ fspId, ...params }, auth) => {
  return client.executeRequest(POST, loadURL(fspId), {
    data: { ...params },
    queryParams: {
      adminUserId: auth.id,
    },
    returnModel,
  });
};
