import { Model, attr } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class FspAiEvents extends Model {
  static get modelName() {
    return 'FspAiEvents';
  }

  static reducer({ type, data }, FspAiEvents) {
    const id = data?.props?.id;

    switch (type) {
      case LOAD.ACTION:
        FspAiEvents.delete();
        break;

      case LOAD.SUCCESS:
        FspAiEvents.delete();
        data?.response?.forEach((r) => FspAiEvents.upsert(r));
        break;

      case CREATE.SUCCESS:
      case UPDATE.SUCCESS:
        if (data?.response) {
          FspAiEvents.upsert(data.response);
        }
        break;

      case REMOVE.SUCCESS:
        if (FspAiEvents.idExists(id)) {
          FspAiEvents.withId(id).delete();
        }
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      fspId: attr(),
      id: attr(),
      insUserId: attr(),
      insightEventIdStr: attr(),
      notifyOffOn: attr(),
      offset: attr(),
      pilotOffOn: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

const name = FspAiEvents.modelName;

export const CREATE = createAddConst(name);
export const LOAD = createRequestConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
