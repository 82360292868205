import { Model, attr } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createRequestConst,
  createUpdateConst,
} from '../util';
import { addDerived } from './util/fspConfigPayALoan';

export default class FspConfigPayALoan extends Model {
  static get modelName() {
    return 'FspConfigPayALoan';
  }

  static reducer({ type, data }, FspConfigPayALoan) {
    switch (type) {
      case CREATE_PAL_CORE_DIRECT.SUCCESS:
      case CREATE_PAL_CONFIG.SUCCESS:
      case LOAD.SUCCESS:
        FspConfigPayALoan.delete();
        FspConfigPayALoan.create(addDerived(data?.response));
        break;

      case CREATE.SUCCESS:
        FspConfigPayALoan.create(addDerived(data?.response));
        break;

      case UPDATE.SUCCESS:
        FspConfigPayALoan.upsert(addDerived(data?.response));
        break;

      case UPDATE_PAL_CONFIG.SUCCESS:
        FspConfigPayALoan.upsert(addDerived(data?.response));
        break;

      case UPDATE_PAL_CONFIG.FAILURE:
        FspConfigPayALoan.delete();
        break;

      case LOAD.FAILURE:
        FspConfigPayALoan.delete();
        break;
      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      id: attr(),
      href: attr(),
      fspId: attr(),
      minAuthQuestion: attr(),
      maxAuthQuestion: attr(),
      feeBundleOffOn: attr(),
      debitCardOffOn: attr(),
      creditCardOffOn: attr(),
      ssoUserOffOn: attr(),
      enrolledUserOffOn: attr(),
      guestUserOffOn: attr(),
      statementImportFrom: attr(),
      partialImportOffOn: attr(),
      paymentPurposeOffOn: attr(),
      paymentPurposeCoreOffOn: attr(),
      holdAccountRouting: attr(),
      holdAccountNumber: attr(),
      holdAccountNumberSuffix: attr(),
      holdAccountType: attr(),
      externalDdaOffOn: attr(),
      feeHoldingAccountRouting: attr(),
      feeHoldingAccountNumber: attr(),
      feeHoldingAccountSuffix: attr(),
      feeHoldingAccountType: attr(),
      feeTransactOnBehalf: attr(),
      feeTransactOnBehalfDerived: attr(),
      format: attr(),
      createdAt: attr(),
      insAdminUserId: attr(),
      ifpPalOnOff: attr(),
      ifpDebitCard: attr(),
      ifpExtDda: attr(),
      recurringPaymentFeeOffOn: attr(),
      singlePaymentFeeOffOn: attr(),
      updatedAt: attr(),
      updatedAtDerived: attr(),
      updUserId: attr(),
      updAdminUserId: attr(),
      fsp: attr(),
    };
  }
}

const name = FspConfigPayALoan.modelName;

export const CREATE = createAddConst(name);
export const CREATE_PAL_CORE_DIRECT = createAddConst(name);
export const LOAD = createRequestConst(name);
export const UPDATE = createUpdateConst(name);
export const UPDATE_PAL_CONFIG = createUpdateConst(name);
export const CREATE_PAL_CONFIG = createAddConst(name);

export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const update = createAction(UPDATE);
export const updatePalConfig = createAction(UPDATE_PAL_CONFIG);
export const createPalConfig = createAction(CREATE_PAL_CONFIG);
export const createPalCoreDirect = createAction(CREATE_PAL_CORE_DIRECT);
