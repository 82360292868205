import { client, formatQueryParams } from 'util/sdk';
import { pick } from 'ramda';
import {
  DESCRIPTION_CODE,
  FSPS,
  LIMIT_CODE,
  LIMIT_RULE_CODE,
  LIMITS_CODE,
  RESET_LIMITS,
  TYPE_CODE,
  PAYER_PREFIX,
} from 'consts';
import { POST, DELETE } from '../util/api';
import flattenObjects from '../util/flattenObjects';

// order here matters, do not re-order!!
const limitKeys = [TYPE_CODE, DESCRIPTION_CODE];
const limitAndLimitRuleKeys = [...limitKeys, LIMIT_RULE_CODE];
// only want these flattened object keys
const limitRuleKeys = [
  'description',
  'inServiceLessThanEqualToMonths',
  'minimumTransactionBillPayAmount',
  'minimumTransactionTransferAmount',
  'minimumTransactionTransferToAmount',
  'minimumTransactionTransferFromAmount',
  'minimumTransactionP2pAmount',
  'minimumTransactionPalDdaAmount',
  'minimumTransactionPalExternalDdaAmount',
  'minimumTransactionPalExternalCardAmount',
  'maximumTransactionBillPayAmount',
  'maximumTransactionTransferAmount',
  'maximumTransactionTransferToAmount',
  'maximumTransactionTransferFromAmount',
  'maximumTransactionP2pAmount',
  'maximumTransactionPalDdaAmount',
  'maximumTransactionPalExternalDdaAmount',
  'maximumTransactionPalExternalCardAmount',
  'dailyLimitsAmount',
  'dailyLimitsBillPayAmount',
  'dailyLimitsTransferAmount',
  'dailyLimitsTransferToAmount',
  'dailyLimitsTransferFromAmount',
  'dailyLimitsP2pAmount',
  'dailyLimitsPalDdaAmount',
  'dailyLimitsPalExternalDdaAmount',
  'dailyLimitsPalExternalCardAmount',
  'weeklyLimitsAmount',
  'weeklyLimitsBillPayAmount',
  'weeklyLimitsTransferAmount',
  'weeklyLimitsTransferToAmount',
  'weeklyLimitsTransferFromAmount',
  'weeklyLimitsP2pAmount',
  'weeklyLimitsPalDdaAmount',
  'weeklyLimitsPalExternalDdaAmount',
  'weeklyLimitsPalExternalCardAmount',
  'monthlyLimitsAmount',
  'monthlyLimitsBillPayAmount',
  'monthlyLimitsTransferAmount',
  'monthlyLimitsTransferToAmount',
  'monthlyLimitsTransferFromAmount',
  'monthlyLimitsP2pAmount',
  'monthlyLimitsPalDdaAmount',
  'monthlyLimitsPalExternalDdaAmount',
  'monthlyLimitsPalExternalCardAmount',
];

const combineLimitsAndLimitRules = (limit, limitrule) =>
  limit
    .filter(({ type }) => !type.startsWith(PAYER_PREFIX)) // exclude cusom payer level rules since they don't apply in different environments
    .map((r) => {
      const rules = limitrule.filter(({ limitId }) => limitId === r.id);

      r.limitrule = flattenObjects(rules).map((r) => pick(limitRuleKeys, r));

      return r;
    })
    .map((r) => pick(limitAndLimitRuleKeys, r));

export const add = ({ fspId, ...data }, auth) =>
  client.fsps(fspId).limits.create(data, { adminUserId: auth.id });

export const load = ({ fspId, ...params }) =>
  client.fsps(fspId).limits.query(formatQueryParams(params));

export const remove = ({ id, fspId }, auth) =>
  client.executeRequest(DELETE, `/${FSPS}/${fspId}/${LIMITS_CODE}/${id}`, {
    queryParams: { adminUserId: auth.id },
    returnModel: { name: LIMIT_CODE },
  });

export const update = ({ id, fspId, ...data }, auth) =>
  client.executeRequest(POST, `/${FSPS}/${fspId}/${LIMITS_CODE}/${id}`, {
    data: pick(limitKeys, data),
    queryParams: { adminUserId: auth.id },
    returnModel: { name: LIMIT_CODE },
  });

export const importAndReset = ({ fspId, limit, limitrule }, auth) =>
  client.executeRequest(POST, `/${FSPS}/${fspId}/${RESET_LIMITS}`, {
    data: {
      [LIMIT_CODE]: combineLimitsAndLimitRules(limit, limitrule),
    },
    queryParams: { adminUserId: auth.id },
    returnModel: { name: LIMIT_CODE },
  });
