import moment from 'moment';
import { NEW_TAB, OFF, ON } from 'consts';

export { convertToDays } from './audit';
export { getPhoneForDisplay, isPhoneNumberValid } from './phone';
export {
  formatCurrency,
  getDdaTypeDerived,
  trimmedDisplayValue,
} from './format';
export {
  date,
  dateAndTime,
  durationHumanized,
  isSameDay,
  formatMonth,
  time,
  toAMPM,
} from './dateAndTime';
export {
  addDerivedData,
  getAddress,
  getLabel,
  getLabelWithId,
  getProfile,
  getStatus,
  isPerson,
} from './payer';
export {
  addOrUpdateSuccessful,
  createAction,
  createActionFailure,
  createActionSuccess,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createRequestConst,
  createSelectConst,
  createUpdateConst,
  createImportConst,
  deleteSuccessful,
} from './actions';

export { callAction, saga, getError } from './saga';

export { getAuth, getVerify } from './auth';

export { default as submit } from './form';

export { ORM } from 'rootReducer';

export { default as localStorage } from './localStorage';

export {
  getFirstEntryInArrayAsInteger,
  isNilOrEmpty,
  stringToInt,
} from './consts';

export const path = (base, path) => `${base}${path}`;

export const removeEmpty = (obj = {}) => {
  return Object.keys(obj)
    .filter((k) => obj[k] != null) // Remove undef. and null.
    .reduce(
      (newObj, k) =>
        typeof obj[k] === 'object'
          ? { ...newObj, [k]: removeEmpty(obj[k]) } // Recurse.
          : { ...newObj, [k]: obj[k] }, // Copy value.
      {}
    );
};

export const duration = (duration) => {
  return moment
    .duration(Math.max(0, duration), 'seconds')
    .format('m:ss', { trim: false });
};

export const isOff = (value) =>
  typeof value === 'string' && value.toLowerCase() === OFF;

export const isOn = (value) =>
  typeof value === 'string' && value.toLowerCase() === ON;

export const toOnOff = (value) => (value ? ON : OFF);

export const boolToOffOn = (value) => (value ? ON : OFF);

export const b64toBlob = (b64Data, mimeType, sliceSize = 512) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    byteArrays.push(new Uint8Array(byteNumbers));
  }

  return new Blob(byteArrays, { type: mimeType });
};

export const downloadFile = (blob, filename) => {
  if (
    window.navigator &&
    typeof window.navigator.msSaveOrOpenBlob !== 'undefined'
  ) {
    // IE doesn't allow using a blob object directly as link href.
    // Workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Other browsers
    // Create a link pointing to the ObjectURL containing the blob
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');

    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', NEW_TAB);
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  }
};

export const base64ToExcel = (base64String) => {
  // Decode Base64 string to binary data
  const byteCharacters = window.atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const mimeType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  // Create a Blob from the binary data with the correct MIME type for Excel files
  return new Blob([byteArray], { type: mimeType });
};

export const viewHeightWithoutAppbar = (theme) => ({
  [theme.breakpoints.up('sm')]: {
    height: `calc(100vh - ${
      theme.mixins.toolbar[theme.breakpoints.up('sm')].minHeight
    }px)`,
  },
  [theme.breakpoints.down('sm')]: {
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
  },
});

export const fullName = ({ firstName, middleName, lastName }) =>
  `${firstName ? `${firstName} ` : ''}${middleName ? `${middleName} ` : ''}${
    lastName ?? ''
  }`;

export const extractPhone = (phone) =>
  phone ? phone.replace(/\D/g, '') : phone;

export const randomNumber = (min = 1, max = 100) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
