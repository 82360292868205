import {
  PAL_INTERNAL_ACCOUNT,
  PAL_EXTERNAL_ACCOUNT,
  PAL_DEBIT_CARD,
  PAL_DISBURSE_TRANS_TYPE_ACH,
  PAL_DISBURSE_TRANS_TYPE_CORE_DC,
  P2P_DISBURSE_TRANS_TYPE_RTP,
  P2P_DISBURSE_TRANS_TYPE_FEDNOW,
  PAL_ACH,
  PAL_CORE_DC,
  P2P_RTP,
  P2P_FEDNOW,
  P2P_DDA,
} from 'consts';
import { formatCurrency } from '../../util';

const BuildDerivedId = (fundingTypeId) => {
  let derivedValue = '';

  switch (fundingTypeId) {
    case 1000:
      derivedValue = PAL_INTERNAL_ACCOUNT;
      break;
    case 2000:
      derivedValue = PAL_EXTERNAL_ACCOUNT;
      break;
    case 3000:
      derivedValue = PAL_DEBIT_CARD;
      break;
    case 4000:
      derivedValue = P2P_DDA;
      break;
    default:
      break;
  }
  return derivedValue;
};

const BuildDisburseTransactionTypeIdentifierDerivedId = (
  disburseTransactionTypeIdentifier
) => {
  let derivedValue = '';

  switch (disburseTransactionTypeIdentifier) {
    case PAL_DISBURSE_TRANS_TYPE_ACH:
      derivedValue = PAL_ACH;
      break;
    case PAL_DISBURSE_TRANS_TYPE_CORE_DC:
      derivedValue = PAL_CORE_DC;
      break;
    case P2P_DISBURSE_TRANS_TYPE_RTP:
      derivedValue = P2P_RTP;
      break;
    case P2P_DISBURSE_TRANS_TYPE_FEDNOW:
      derivedValue = P2P_FEDNOW;
      break;
    default:
      break;
  }
  return derivedValue;
};

// eslint-disable-next-line import/prefer-default-export
export const addDerived = ({ ...item } = {}) => {
  item.feesAmountDerived = formatCurrency(item.feesAmount);
  item.fundingTypeDerived = BuildDerivedId(item.fundingTypeId);
  item.disburseTransactionTypeIdentifierDerived =
    BuildDisburseTransactionTypeIdentifierDerivedId(
      item.disburseTransactionTypeIdentifier
    );
  return {
    ...item,
  };
};
